<template>
  <!-- drag/drop.prevent keep the image from opening in the browser. May want to also do this on the parent -->
  <div @dragover.prevent @drop.prevent style="max-height: 100%; display: flex; flex-direction: column; justify-content: flex-start; align-items: flex-start">
    <div v-if="isOpen && singlePickMode && images?.length > 0" style="width: 100%">
      <ImageCropper :selectedImageServerId="cachedActiveServerId" @onCrop="uploadCroppedImage" />
    </div>
    <div style="width: 100%; height: 100%; display: flex; flex-direction: column; justify-content: flex-start; align-items: flex-start">
      <p v-if="singlePickMode" class="note">Choose a photo to use.</p>
      <!-- Upload new and show photos section -->
      <div
        v-if="images"
        class="uploadedContent"
        @drop="
          $event => {
            dragFile($event);
            isDragOverActive = false;
          }
        "
        :style="{
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          boxShadow: isError ? '0 0 0 5px rgba(188, 73, 73, 0.35)' : 'none',
        }"
        @dragenter="isDragOverActive = true"
        style="width: 100%; height: 100%; flex-direction: column; justify-content: flex-start; align-items: flex-start"
      >
        <input
          type="file"
          style="width: 100%; height: 100%; position: absolute; inset: 0 auto auto 0; opacity: 0; cursor: pointer"
          :multiple="!singlePickMode"
          accept="image/*,video/*"
          ref="fileInput"
          @change="onImageReadyToUploadV2"
          id="imageFileInput"
        />
        <!-- Also repeated at the bottom of there are enough photos -->
        <div
          class="uploadBtn"
          for="imageFileInput"
          :class="{ center: images?.length < 1 }"
          @click="clickUploadImage"
          style="width: 100%"
          :style="{
            height: images.length ? 'auto' : 'calc(100% - 10px)',
            padding: !images.length || !isThumbnailsVisible ? '40px 10px 30px' : '25px 10px 25px',
          }"
        >
          <div name="plus" size="small" />
          {{ singlePickMode ? "Upload a new Photo or Video" : "Add or Drag in Photos or Videos" }}
          <div v-if="isUploadInProgress" class="uploadBtn__progressbar">
            <div class="uploadBtn__progressbar-indicator" :style="`width: ${uploadProgress}%`"></div>
            uploading<EllipsisAnimated />
          </div>
        </div>

        <div :class="{ transparentForClicks: isDragOverActive }">
          <slot name="thumbnailsList" :items="images" :removeItem="removeMediaFile" />
        </div>

        <SrpMasonry
          v-if="isThumbnailsVisible && !Object.keys($slots).includes('thumbnailsList')"
          :items="images"
          :columns="columnsNumber ? columnsNumber : { mobile: 2, tablet: 3, 'tablet-large': 4, laptop: 5, desktop: 5, 'desktop-wide': 5 }[screenSize]"
          :columnGap="screenSize === 'mobile' ? 9 : 11"
          customKey="serverId"
          :class="{ transparentForClicks: isDragOverActive }"
        >
          <template #default="{ item, index, rebuildMasonry }">
            <div
              v-if="!singlePickMode || !isVideoFileType(item.serverId)"
              :key="index"
              :class="{
                photoNode: true,
                'photoNode--active': item.serverId === cachedActiveServerId,
              }"
              style="margin-bottom: 10px"
            >
              <CloseDeleteButton
                v-if="showRemove"
                class="remove-button"
                size="tiny"
                @click="
                  removeMediaFile(
                    images.findIndex(i => i.serverId === item.serverId),
                    true
                  )
                "
                icon="trashcan"
              />
              <LoaderComponent v-if="item.finishedUploading === false && !singlePickMode" active style="color: #fff" />
              <div @click="imageClicked(item)" class="image-container" :class="{ active: item.active }">
                <!--Video-->
                <template v-if="isVideoFileType(item.serverId)">
                  <SrpFileThumbnail class="cms-video" :fileName="item.serverId" @imageLoad="rebuildMasonry" />
                </template>
                <!--Image-->
                <template v-else>
                  <img v-if="item.finishedUploading !== false" :src="`${contentBaseUri}/cms/images/expeditions/thumbnocrop/${item.serverId}`" class="rounded" @load="rebuildMasonry" />
                </template>
              </div>
            </div>
          </template>
        </SrpMasonry>

        <!-- Also repeated at the top -->
        <label v-if="images.length > 10 && isThumbnailsVisible" class="uploadBtn" for="image" @click="clickUploadImage">
          <div name="plus" size="small" />
          {{ singlePickMode ? "Upload a new Photo or Video" : "Add or Drag in Photos or Videos" }}
          <div v-if="isUploadInProgress" class="uploadBtn__progressbar">
            <div class="uploadBtn__progressbar-indicator" :style="`width: ${uploadProgress}%`"></div>
            uploading<EllipsisAnimated />
          </div>
        </label>
      </div>
      <div>Videos take a bit to process and clips under a minute work best.</div>
      <div v-if="showWarning" class="warning">Max {{ maxNumberOfFilesToUploadAtOnce }} uploads at a time.</div>
      <div v-if="showMinSizeOrResolutionWarning" class="warning">
        Some images are under
        {{ validateBy === "size" ? `${isCollab ? minCollabFileSizeErrorInKB : minFileSizeWarningInKB}KB` : `${minFileResolutionWarningInPX}px*${minFileResolutionWarningInPX}px` }}, we recommend
        uploading higher quality images.
      </div>
      <div v-if="uploadError?.length > 0" class="warning" data-tooltip="Questions or issues with uploads? Email support@shrpa.com">{{ uploadError }}</div>
      <div v-if="isCollab" style="margin-bottom: 5px">
        <b>Note:</b> Collab images must be at least {{ validateBy === "size" ? `${minCollabFileSizeErrorInKB}KB` : `${minCollabFileResolutionErrorInPX}px*${minCollabFileResolutionErrorInPX}px` }}
      </div>
      <SrpButton v-if="showClose" style="margin: 8px 0 1em !important; float: right; align-self: flex-end" @click="close" color="gray" size="small">Close</SrpButton>
    </div>

    <!-- Generic Message Modal -->
    <SrpModal v-model:isVisible="showMessageModal">
      <template #content>
        <div style="white-space: pre-wrap">
          <h4>{{ messageModalText }}</h4>
        </div>
      </template>
      <template #footer>
        <SrpButton @click="showMessageModal = false">Ok</SrpButton>
      </template>
    </SrpModal>
  </div>
</template>

<script lang="ts">
import { ref, defineComponent, inject } from "vue";
import axios, { AxiosError } from "axios";
import { BlockBlobClient } from "@azure/storage-blob";
import FileUtils, { FileMimeTypes } from "@logic/FileUtils";
import { getImageResolution, getVideoResolution } from "@helpers/GetImageOrVideoResolution";
import ImageBlobReduce from "image-blob-reduce";
import ImageUploader from "@logic/ImageUploader";
import { useToastsStore } from "@stores/toasts";
import { MAX_CONCURRENT_UPLOADS_DEFAULT, ParallelAssetUploader } from "./ParallelAssetUploader";
import { mapState } from "pinia";
import { useUserProfileStore } from "@stores/userProfileStore";

// Types
import { PageAdventureList } from "@contracts/pages";
import { ScreenSize } from "@contracts/screenSize";
import { UploadedImage } from "@contracts/uploadedImage";

// Components
import EllipsisAnimated from "@components/ui/EllipsisAnimated.vue";
import ImageCropper from "./ImageCropper.vue";
import LoaderComponent from "@components/Loader/Loader.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import SrpMasonry from "@components/ui/SrpMasonry.vue";
import SrpModal from "@components/ui/SrpModal.vue";
import VideoRender from "@components/VideoRender.vue";
import SrpFileThumbnail from "@components/ui/SrpFileThumbnail.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import CloseDeleteButton from "@components/ui/CloseDeleteButton.vue";

// NOTE! We now have two emit modes from this components.  The original one watches the images array and events any changes to it
// The newer Photo Gallery code events off each photo upload (since it updates the server)

export const MinCollabFileSizeErrorInKB = 500; // Dropped from 700 to 500, should probably use pixel dimensions instead
// Note: ImageUplaods only retry on network error
const ImageUploadRetryCount = 5;
const VideoUploadRetryCount = 4;
const TimeBetweenRetriesInMs = 4000;

export default defineComponent({
  name: "UploadPhotoForm",

  components: {
    CloseDeleteButton,
    IconEmbedded,
    EllipsisAnimated,
    SrpFileThumbnail,
    ImageCropper,
    LoaderComponent,
    SrpButton,
    SrpMasonry,
    SrpModal,
    VideoRender,
  },

  props: {
    img: { type: Array as () => Array<any> | null, default: null },
    activeServerId: { type: String, required: false, default: "" },
    singlePickMode: { type: Boolean, default: false },
    showClose: { type: Boolean, default: false },
    autoSelect: { type: Boolean, default: false },
    isOpen: { type: Boolean, default: false },
    showRemove: { type: Boolean, default: false },
    isCollab: { type: Boolean, default: false },
    collabImagesUsed: { type: Array as () => Array<string>, default: () => [] },
    collabImagesUnused: { type: Array as () => Array<string>, default: () => [] },
    isThumbnailsVisible: { type: Boolean, default: true },
    isError: { type: Boolean, default: false },
    columnsNumber: { type: Number, default: 0, required: false },
  },

  emits: ["imageUploadedToServer", "close", "removeMediaFile", "uploadImagesChanged", "uploadProgressChange", "fileTooSmallError", "invalidFileType", "uploadingStarted"],

  data() {
    return {
      globalLog: inject("globalLog") as any,
      globalRemoteLogger: inject("globalRemoteLogger") as any,
      screenSize: inject("screenSize") as ScreenSize,
      userProfileStore: useUserProfileStore(),

      // @ts-ignore
      contentBaseUri: globalThis.Bootstrap.Config.contentCdnBaseUri,
      // Bumped this up now that we only upload so many at at a time.
      maxNumberOfFilesToUploadAtOnce: 100,

      validateBy: "size" as "size" | "resolution",

      minFileSizeWarningInKB: 250,
      minCollabFileSizeErrorInKB: MinCollabFileSizeErrorInKB,

      minFileResolutionWarningInPX: 1024,
      minCollabFileResolutionErrorInPX: 720,

      maxVideoSizeInMB: 600,

      isUploadInProgress: false,
      uploadProgress: 0,

      // Note: This is set server-side also in the config
      // Retired now that we client-side resize maxFileSizeInMB: 13,
      showWarning: false,
      showMinSizeOrResolutionWarning: false,
      uploadError: null as string | null,
      mainImage: null,
      showMessageModal: false,
      messageModalText: "Not set",

      isDragOverActive: false, // for the thumbnails to not block the drag&drop event
      cachedActiveServerId: this.activeServerId ?? this.img[0]?.serverId ?? "", // Used to keep track of the active image when in singlePickMode
    };
  },

  computed: {
    ...mapState(useUserProfileStore, ["getViewingUserProfile", "getActingUserProfile"]),
    images() {
      return this.img;
    },
    justPhotos(): Array<UploadedImage> {
      return this.images.filter(asset => FileUtils.isVideoFileType(asset?.serverId, null) === false);
    },
  },

  watch: {
    activeServerId(value: string) {
      this.cachedActiveServerId = this.activeServerId;
    },
    uploadProgress() {
      this.$emit("uploadProgressChange", { isUploadInProgress: Boolean(this.isUploadInProgress), uploadProgress: this.uploadProgress });
    },
  },

  methods: {
    async uploadCroppedImage(file: File) {
      var imageDetails = {
        name: file.name,
        size: file.size,
        serverId: null,
        finishedUploading: ref(false),
      } as UploadedImage;
      await this.uploadImage(imageDetails, file, true, this.cachedActiveServerId, ImageUploadRetryCount);
    },
    dragFile(e) {
      this.globalLog.info("Drag file");
      this.onImageReadyToUploadV2(e);
    },
    close() {
      this.showWarning = false;
      this.showMinSizeOrResolutionWarning = false;
      this.uploadError = null;
      this.$emit("close");
    },
    imageClicked(image: UploadedImage) {
      if (!this.singlePickMode) return;
      if (!image.serverId || image.serverId.length == 0) return;
      this.cachedActiveServerId = image.serverId;
    },
    clickUploadImage() {
      this.showWarning = false;
      this.uploadError = null;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.$refs.fileInput.click();
    },
    async onImageReadyToUploadV2(e) {
      if (!e?.target?.files?.length && !e?.dataTransfer?.files?.length) {
        // Note: This method only sometimes gets called if not files are selected
        // Ex. In Chrome desktop, it only does if you upload a file, then try to upload again but cancel
        this.globalRemoteLogger.info(`ImageUpload NoFilesChosen: user=${this.getActingUserProfile?.sherpaId}, collab=${this.isCollab}`, false, false, {
          creatorId: this.getActingUserProfile?.sherpaId,
          isCollab: this.isCollab,
        });
        return;
      }
      // V2 uses logic to only upload a certain number of files at a time
      // The previous logic could crash mobile browsers if trying to upload too many files.
      let assets: File[] = Array.from(e.target.files).length ? Array.from(e.target.files) : Array.from(e.dataTransfer.files);
      if (assets.length > this.maxNumberOfFilesToUploadAtOnce) {
        this.showWarning = true;
        assets = assets.slice(0, this.maxNumberOfFilesToUploadAtOnce);
      }
      await this.uploadFiles(assets);
    },
    async uploadFiles(assets: File[], fromImageCropper = false) {
      this.$emit("uploadingStarted");
      var uploader = new ParallelAssetUploader();
      await uploader.uploadImagesAndVideos(
        assets,
        async (assetToUpload: File) => {
          try {
            let canBeUploaded = await this.inputFileCanBeUploaded(assetToUpload);
            const assetSizeInMB = assetToUpload.size / 1024 / 1024;
            this.globalRemoteLogger.info(
              `uploadingStarted name=${assetToUpload.name} sizeBeforeResize=${assetSizeInMB}MB, canBeUploaded=${canBeUploaded}, user=${this.getActingUserProfile?.sherpaId}, collab=${this.isCollab}`,
              false,
              false,
              {
                originalSizeInMB: assetSizeInMB,
                canUpload: canBeUploaded,
                creatorId: this.getActingUserProfile?.sherpaId,
                isCollab: this.isCollab,
              }
            );
            if (!canBeUploaded) return;

            if (FileUtils.isVideoFileType(assetToUpload.name, assetToUpload.type)) {
              this.globalRemoteLogger.info("VideoUpload: " + assetToUpload.name);
              // @ts-ignore
              assetToUpload.finishedUploading = ref(false);
              await this.uploadVideo(assetToUpload, VideoUploadRetryCount);
            } else {
              // We're resizing client-side now before sending to the server.
              // This way we don't have a size limit and everything performs better.
              let resizedImage = await this.resizeImage(assetToUpload);

              if (!(await this.validateFile(assetToUpload))) {
                this.showMinSizeOrResolutionWarning = true;
              }

              var imageDetails = {
                name: assetToUpload.name,
                size: assetToUpload.size,
                serverId: null,
                finishedUploading: ref(false),
              } as UploadedImage;
              await this.uploadImage(imageDetails, resizedImage, this.singlePickMode ? false : this.autoSelect, fromImageCropper ? this.cachedActiveServerId : null, ImageUploadRetryCount);
            }
          } catch (error) {
            // Note: Currently only checking for heic files on fail
            if (await this.checkForHeicUpload(assetToUpload)) {
              return;
            }
            const isNetworkError = error.message?.toLowerCase().includes("network error");
            this.globalRemoteLogger.error(`--ALERT--UploadError PREP: ${this.buildMessageToLog(error)}, fileName=${assetToUpload?.name}, size=${assetToUpload?.size}, stack=${error?.stack}`, true);
            this.notifyUserOfUploadError(isNetworkError);
            try {
              // Upload the file as unprocessed so we can troubleshoot
              var uploadedFileName = await ImageUploader.uploadItineraryImageBlob(assetToUpload, false, assetToUpload.name);
              this.globalRemoteLogger.info(`Uploading unprocessed file success: uploadedFileName=${uploadedFileName}, fileName=${assetToUpload?.name}, size=${assetToUpload?.size}`, true);
            } catch (innerError) {
              this.globalRemoteLogger.warn(`Uploading unprocessed file failed: fileName=${assetToUpload?.name} ${innerError}`, true);
            }
          }
        },
        MAX_CONCURRENT_UPLOADS_DEFAULT,
        (isUploadInProgress: boolean, progress: number) => {
          this.isUploadInProgress = isUploadInProgress;
          this.uploadProgress = progress;
        }
      );
    },
    async checkForHeicUpload(assetToUpload: File) {
      try {
        if ((await FileUtils.getFileMimeType(assetToUpload)) === FileMimeTypes.heic) {
          this.globalRemoteLogger.warn(`UploadAsset Warn: Heic file fileName=${assetToUpload?.name}, size=${assetToUpload?.size}`, true);
          useToastsStore().warn({ message: `.heic files must be converted to jpeg format:\n${assetToUpload?.name}`, expiresInMs: 10000 });
          return true;
        }
        return false;
      } catch (error) {
        this.globalRemoteLogger.error(`checkForHeicUpload failed: ${error?.message ?? error} fileName=${assetToUpload?.name}, size=${assetToUpload?.size}, stack=${error?.stack}`, true);
        return false;
      }
    },
    // Validates if the file should be uploaded and sets the uploadError message
    async inputFileCanBeUploaded(inputFile: File): Promise<boolean> {
      let fileSizeInKB = Math.floor(inputFile.size / 1024);
      let fileSizeInMB = Math.floor(fileSizeInKB / 1024);
      // File Type
      var error = FileUtils.isFileSupportedForUpload(inputFile.name, inputFile.type, inputFile.size);
      if (error?.length > 0) {
        useToastsStore().warn({ message: error, expiresInMs: 10000 });
        // Show the error and skip this asset
        // Note: Still uploading the file so we can troubleshoot
        // deprecated var uploadedFileName = await ImageUploader.uploadItineraryImageBlob(inputFile, false, inputFile.name);
        this.uploadError = error;
        this.globalRemoteLogger.warn(`UploadAsset Warn: Invalid FileType: ${inputFile.type} ${inputFile.name} size=${fileSizeInKB}K, collab=${this.isCollab}`);
        // Note: Type is often empty, what we likely want is the extension (so passing the filename)
        this.$emit("invalidFileType", inputFile.name);
        return false;
      }
      // Min file size
      if (this.isCollab === true) {
        if (!(await this.validateFile(inputFile, true))) {
          // Note: Still uploading the file so we can troubleshoot
          var uploadedFileName = await ImageUploader.uploadItineraryImageBlob(inputFile, false, inputFile.name);
          this.uploadError = `We couldn't upload some of your images because they were too small.`;
          // Julys 2024 Update: Removed showing the actual image and just show a toast with the file name
          useToastsStore().warn({ message: `${inputFile.name} is too small,\ncollab images must be > ${MinCollabFileSizeErrorInKB}KB` });
          this.globalRemoteLogger.info(
            `UploadAsset Warn: TooSmall for ${this.getActingUserProfile?.sherpaId}: ${fileSizeInKB}K ${uploadedFileName} ${inputFile.name} ${inputFile.type}, collab=${this.isCollab}`
          );
          this.$emit("fileTooSmallError", inputFile.name);
          return false;
        }
      }
      // Video max file size
      let isVideo = FileUtils.isVideoFileType(inputFile.name, inputFile.type);
      if (isVideo && inputFile.size > this.maxVideoSizeInMB * 1024 * 1024) {
        this.uploadError = `Videos must be less than ${this.maxVideoSizeInMB}MB`;
        this.globalRemoteLogger.info(`--ALERT-- UploadAsset Warn: TooBig ${fileSizeInMB}M ${inputFile.name} ${inputFile.type}, collab=${this.isCollab}`);
        return false;
      }

      // Must be good
      return true;
    },
    async validateFile(file: File | UploadedImage, isStrict = false) {
      let isFileValid = false;

      const minSize = (isStrict ? this.minCollabFileSizeErrorInKB : this.minFileSizeWarningInKB) * 1024;
      const minResolution = isStrict ? this.minFileResolutionWarningInPX : this.minCollabFileResolutionErrorInPX;

      if (this.validateBy === "size") {
        isFileValid = file.size >= minSize;
      } else {
        const isVideo = FileUtils.isVideoFileType(file.name, file.type);
        const fileResolution = isVideo ? await getVideoResolution(file as File) : await getImageResolution(file as File);
        isFileValid = fileResolution.w >= minResolution || fileResolution.h >= minResolution;
      }

      return isFileValid;
    },
    async uploadVideo(file, retryCount: number) {
      let videoId = null;
      let uploadState = "not-started";
      let start = performance.now();
      try {
        this.globalRemoteLogger.info(`ClientUploading Video ${file.name} ${file.size / 1024 / 1024}MB`);
        // Get a token so we can upload the video directly to storage and then ask the services to process it.
        let slotUri = `${import.meta.env.VITE_API_URL}/videoupload/slot?fileName=${encodeURIComponent(file.name)}`;
        const { data } = await axios.get(slotUri);
        // use this to attach the video to the adventure
        videoId = data.videoId;
        // Use this for the file to upload to blob storage (more comments on the server)
        this.globalRemoteLogger.info(`VideoUpload Slot granted ${videoId} for file ${data.uploadFileName}`);
        uploadState = "slot-granted";

        // Set the serverId early so they can select the video even before it's done processing.
        // This should work fine unless they refresh the page before the video is done processing...
        file.serverId = videoId;

        // Upload the video to storage
        const blockBlobClient = new BlockBlobClient(data.authToken);
        this.globalRemoteLogger.info(`VideoUpload Starting. ${videoId} size=${file.size}`);
        // Uploads a browser Blob/File/ArrayBuffer/ArrayBufferView object to block blob. per https://learn.microsoft.com/en-us/javascript/api/@azure/storage-blob/blockblobclient?view=azure-node-latest
        await blockBlobClient.uploadData(file);
        uploadState = "uploadToAzure-complete";

        file.finishedUploading.value = true;

        let middle = performance.now();
        this.globalRemoteLogger.info(`VideoUpload complete, now processing... ${videoId}`);

        // Now request that the server process it.
        let processUri = `${import.meta.env.VITE_API_URL}/videoupload/${data.uploadFileName}`;
        await axios.post(processUri);
        uploadState = "uploadToShrpa-complete";
        let end = performance.now();
        // Update: Moved this to after the video uploads, which is the same way the photo upload works.
        this.$emit("imageUploadedToServer", file);
        this.globalRemoteLogger.info(`VideoUpload and Process Completed! ${videoId} UploadTime=${this.getSecondsDiff(start, middle)}, ProcessTime=${this.getSecondsDiff(middle, end)}`);
      } catch (error: any | AxiosError) {
        // NOTE: Similar logic in the uploadImage()
        const isNetworkError = error.message?.toLowerCase().includes("network error");
        // Note: Retrying even non-network errors for video since there are multiple calls and there may be different error messages
        if (retryCount > 0) {
          const triesLeft = retryCount - 1;
          this.globalRemoteLogger.info(`UploadRetry Video: ${error}: runtime=${this.getRuntimeInMs(start)}ms for ${file?.name}, id=${videoId}, ${triesLeft} tries left`, true);
          // Wait a few seconds
          await new Promise(r => setTimeout(r, TimeBetweenRetriesInMs));
          // Retry
          return this.uploadVideo(file, triesLeft);
        }
        this.globalRemoteLogger.error(`--ALERT--UploadError Video: ${this.buildMessageToLog(error)}, uploadState=${uploadState}, id=${videoId}, fileName=${file?.name}, stack=${error?.stack}`, true);
        // Note: This probably isn't necessary now that we don't emit until after the upload has succeeded
        this.notifyUserOfUploadError(isNetworkError);
      }
    },
    getSecondsDiff(startInMs: number, endInMs: number) {
      let diff = endInMs - startInMs;
      return Math.floor(diff * 1000);
    },
    calculateAspectRatioFit(srcWidth, srcHeight, maxWidth, maxHeight) {
      let ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
      return { width: srcWidth * ratio, height: srcHeight * ratio };
    },
    async resizeImage(imageInput) {
      let maxDimension = 3200;
      let jpegQuality = 0.8;
      var fileType = imageInput.type;
      var inputBlob = new Blob([imageInput], { type: fileType || "application/*" });
      var reducer = new ImageBlobReduce();

      // Override to always output a jpeg at a certain quality https://github.com/nodeca/image-blob-reduce
      reducer._create_blob = function (env) {
        return this.pica.toBlob(env.out_canvas, "image/jpeg", jpegQuality).then(function (blob) {
          env.out_blob = blob;
          return env;
        });
      };

      let resizedImageBlob = await reducer.toBlob(inputBlob, { max: maxDimension });
      return resizedImageBlob;
    },
    async uploadImage(image: UploadedImage, rawFile, autoSelect: boolean, uncroppedImageServerId: string | null, retryCount: number) {
      const startTime = performance.now();
      try {
        this.globalRemoteLogger.info(`ClientUploading Photo ${image.name} ${rawFile.size / 1024 / 1024}MB (was ${image.size / 1024 / 1024}MB) Autoselect=${autoSelect}`);

        var uploadedFileName = await ImageUploader.uploadItineraryImageBlob(rawFile, true, image.name);

        // await new Promise(r => setTimeout(r, 11000)); //Testing
        this.globalRemoteLogger.info(`Uploaded ${image.name} to server as ${uploadedFileName}`);

        if (uploadedFileName) {
          // Set the serverId and then updated the reactive property so it re-renders
          image.serverId = uploadedFileName;
          if (!image.finishedUploading) image.finishedUploading = ref(false);
          image.finishedUploading.value = true;
          // Note: uncroppedImageServerId is only used for the cover photos selection in SummaryForm.vue
          this.globalRemoteLogger.info(
            `UploadPhotoForm.upload: completed runtime=${this.getRuntimeInMs(startTime)}ms for ${image.name}, isCollab=${this.isCollab}, autoSelect=${autoSelect}, uncroppedImageServerId=${uncroppedImageServerId}`
          );
          if (this.autoSelect) this.cachedActiveServerId = image.serverId;
          this.$emit("imageUploadedToServer", image, autoSelect, uncroppedImageServerId);
        }
      } catch (error: any | AxiosError) {
        // NOTE: Similar logic in uploadVideo()
        // Adding a retry in the case of a network error
        const isNetworkError = error.message?.toLowerCase().includes("network error");
        if (retryCount > 0 && isNetworkError) {
          const triesLeft = retryCount - 1;
          this.globalRemoteLogger.info(`UploadRetry Photo: ${error.message}: runtime=${this.getRuntimeInMs(startTime)}ms for ${image?.name}, ${triesLeft} tries left`, true);
          // Wait a few seconds
          await new Promise(r => setTimeout(r, TimeBetweenRetriesInMs));
          // Retry
          return await this.uploadImage(image, rawFile, autoSelect, uncroppedImageServerId, triesLeft);
        } else {
          // Otherwise log and let them know.
          const networkTest = await this.checkInternetConnection();
          this.globalRemoteLogger.error(
            `--ALERT--UploadError Photo: ${this.buildMessageToLog(error)}, runtime=${this.getRuntimeInMs(startTime)}ms for ${image?.name}, ${networkTest}, isCollab=${this.isCollab}, stack=${error?.stack}`,
            true
          );
          // Note: This probably isn't necessary now that we don't emit until after the upload has succeeded
          this.notifyUserOfUploadError(isNetworkError);
        }
      }
    },
    getRuntimeInMs(startTime: number) {
      return Math.floor(performance.now() - startTime);
    },
    buildMessageToLog(error: any | AxiosError): string {
      // Trying to log as much as we can here (although it still isn't as much as we'd like)
      if (axios.isAxiosError(error)) {
        return `AxiosError: ${error?.message} ${error?.code}`;
      } else {
        return `${error?.message ?? error}`;
      }
    },
    async checkInternetConnection(): Promise<string> {
      // This gets called when we have issues uploading images, so checking other endpoints/sites to help troubleshoot.
      let backendApiPingResult = null as string | null;
      // let frontendPingResult = null as string | null;
      let externalPingResult = null as string | null;
      try {
        const externalResult = await axios.get(`https://httpbin.org/get`);
        externalPingResult = "status=" + externalResult?.status ?? "No data";
      } catch (error) {
        externalPingResult = `Error: ${error?.message ?? error}`;
      }
      try {
        // Backend api ping
        const { data } = await axios.get(`${import.meta.env.VITE_API_URL}/ping`);
        backendApiPingResult = data;
      } catch (error) {
        backendApiPingResult = `Error: ${error?.message ?? error}`;
      }
      /* Deprecated since we CDN host
      try {
        // Frontend ping
        const { data } = await axios.get(`/frontend-ping`);
        frontendPingResult = data;
      } catch (error) {
        frontendPingResult = `Error: ${error?.message ?? error}`;
      }*/
      return `ExternalPing: ${externalPingResult}, BackendApiPing: ${backendApiPingResult}`;
    },
    notifyUserOfUploadError(isNetworkError: boolean) {
      // Note: Could probably call this from the video code now also
      let modalMessagePrefix = "Something went wrong with the upload, sorry about that!";
      if (isNetworkError) modalMessagePrefix = "Your network connection seems to be having trouble.";
      this.showModalMessage(`${modalMessagePrefix} \n\nPlease try again and if the issue continues email support@shrpa.com`);
      // Note: The cleanup logic isn't necessary now that we don't emit until after the upload has succeeded, so removed that
    },
    removeMediaFile(index: number, isValidImage: boolean) {
      // this.globalLog.info("Removing image at " + index);
      if (isValidImage) {
        let image = this.images[index];
        if (image.serverId) {
          this.$emit("removeMediaFile", image.serverId);
        }
      }
    },
    isVideoFileType(fileName: string) {
      return FileUtils.isVideoFileType(fileName, null);
    },
    showModalMessage(message) {
      this.messageModalText = message;
      this.showMessageModal = true;
    },
  },
});
</script>

<style scoped lang="scss">
.transparentForClicks {
  pointer-events: none;
}

// Remove button ==============================================================
.remove-button {
  position: absolute;
  inset: 3px 3px auto auto;
  z-index: 3;
}

.modal .image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  //cursor: pointer; //Only applies to a very small set of scenarios (singlePickMode)
  border: 3px solid transparent;
  transition: 0.3s;

  img {
    width: 100%;
  }
  video {
    width: 100%;
  }

  &.active {
  }
}
.note {
  margin: 10px 0;
}

.uploadedContent {
  min-height: 110px;
  padding: 0 10px 0 5px;
  margin-bottom: 8px;
  border: 1px dashed;
  border-radius: 10px;
  overflow: auto;
  flex-direction: column;
}

.uploadedContent.drag {
  box-shadow: inset 0 0 5px #058587;
}

.uploadBtn {
  padding: 16px;
  margin: 0 !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #058587;
  font-size: 1em;
  font-weight: bold;
  cursor: pointer;

  &__progressbar {
    width: 150px;
    height: 7px;
    margin-top: 5px;
    border-radius: 100px;
    position: relative;
    color: rgba(91, 91, 91, 1);
    font: 14px/14px sans-serif;
    text-align: center;
    background: rgba(0, 0, 0, 0.12);
    pointer-events: none;
  }

  &__progressbar-indicator {
    height: 7px;
    margin-bottom: 2px;
    border-radius: 1000px;
    inset: 0 auto auto 0;
    background: rgba(17, 134, 137, 1);
    transition: width 0.3s ease-in-out;
  }
}

.photoNode {
  width: 100%;
  min-height: 30px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 0;
  overflow: hidden;
  text-align: center;

  .ajax-loader {
    margin: 0;
    position: absolute;
    inset: 50% auto auto 50%;
    z-index: -1;
    transform: translate(-50%, -50%);
  }

  .image-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
    }
  }

  &--active {
    outline: 3px #058587 solid !important;
  }
}

.close {
  z-index: 20;
  cursor: pointer;
  position: absolute;
  right: 7px;
  top: 10px;
}

.spinner {
  z-index: 20;
  position: absolute;
  right: 50px;
  top: 40px;
}

.warning {
  color: rgb(155, 0, 0);
  font-weight: bold;
}

@media only screen and (max-width: 767px) {
  .modal .image-container {
    min-height: 85px;
  }

  .uploadedContent {
    height: 100%;
    min-height: 100%;
    // max-height: 460px;
    overflow: auto;
  }
}
</style>
