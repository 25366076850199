<template>
  <div class="pro-tip-form">
    <div class="pro-tip-form__icon-and-title">
      <h5 class="global-h5 pro-tip-form__title">Pro Tip:</h5>
    </div>
    <input
      v-if="props.isEditable"
      class="global-text-input pro-tip-form__tip-input"
      type="text"
      :value="props.value"
      @input="$event => emit('update:value', ($event.target as HTMLInputElement).value)"
    />
    <span v-else class="pro-tip-form__tip-text">{{ props.value }}</span>
  </div>
</template>

<script setup lang="ts">
// Global variables & injections
const contentBaseUri = globalThis.Bootstrap.Config.contentCdnBaseUri;

const props = withDefaults(
  defineProps<{
    value: string;
    isEditable?: boolean;
  }>(),
  {
    value: "",
    isEditable: false,
  }
);

const emit = defineEmits<{
  (e: "update:value", value: string): void;
}>();
</script>

<style scoped lang="scss">
// Red Shrpa logo =============================================================
.red-shrpa-logo {
  width: 20px;
  height: 20px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(236, 86, 59, 1);

  &__img {
    width: 65%;
    height: 65%;
    object-fit: contain;
  }
}

// Pro Tip form ===============================================================
.pro-tip-form {
  &__icon-and-title {
    margin-bottom: 4px;
    display: flex;
    align-items: center;
  }

  &__red-shrpa-logo {
    margin-right: 5px;
  }

  &__title {
    font-weight: 500;
  }

  &__description {
    margin-bottom: 4px;
  }

  &__tip-input {
    // max-width: 400px;
  }

  &__tip-text {
  }
}
</style>
